/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import Link from 'next/link';
import { useMediaQuery } from 'beautiful-react-hooks';
import styles from '../styles/Error.module.css';
import Config from '../config/Config';
import DashboardLayout from '../components/DashboardLayout';

interface GeneralErrorProps {
  pageName?: string;
  title?: string;
  text?: string;
  href?: string;
  button?: string;
}

function GeneralError({ pageName, title, text, href, button }: GeneralErrorProps) {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  return (
    <DashboardLayout pageName={pageName || ''}>
      <section className="flex-grow m-auto">
        <div className={styles.sneakerTop}>
          <img
            src={`/images/${isMobile ? 'mobile-error-top.png' : 'desktop-error-left.png'}`}
            alt="sneaker top"
          />
        </div>
        <div className={styles.ErrorWrapper}>
          <h1>{title}</h1>
          <p>{text}</p>
          <Link href={href as string}>
            <a className="rounded-full button-red">
              <div className="flex py-3 px-4 md:py-4 md:px-6">
                <span className="flex pr-4 md:pr-8">
                  <Image src="/chevron_left_white.svg" alt="Go back home" width={12} height={18} />
                </span>
                {button}
              </div>
            </a>
          </Link>
        </div>
        <div className={styles.sneakerBottom}>
          <img
            src={`/images/${isMobile ? 'mobile-error-bottom.png' : 'desktop-error-right.png'}`}
            alt="sneaker bottom"
          />
        </div>
      </section>
    </DashboardLayout>
  );
}

GeneralError.defaultProps = {
  pageName: 'Something went wrong',
  title: 'Oops, something went wrong',
  text: `We're unable to complete your request, try again later or contact us if the issue persists.`,
  href: `${Config.WORDPRESS_URL}`,
  button: 'SOLESAVY.COM',
};

export default GeneralError;
