import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'cookies';
import { useRouter } from 'next/router';
import { getSsoUrl, useAuth } from '../providers/Auth';
import LoadingSpinner from '../components/LoadingSpinner';
import Config from '../config/Config';
import Oops from './oops';

interface LoginProps extends AppProps {
  token: string;
  error: string | null;
}

function Login({ token, error }: LoginProps) {
  const router = useRouter();
  const { setToken, isAuthenticated } = useAuth();

  useEffect(() => {
    if (token && !error) {
      setToken(token);
    } else if (!error) {
      router.push(getSsoUrl());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const dest = localStorage.getItem('path') || '/';
      localStorage.removeItem('path');
      router.push(dest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return !isAuthenticated && error ? (
    <Oops text={error} href="/logout" button="GO BACK" />
  ) : (
    <LoadingSpinner pageName="Logging in..." />
  );
}
export default Login;

export async function getServerSideProps(context: any) {
  const { code, locale, userState } = context?.query;
  const { req, res } = context;
  const cookies = new Cookies(req!, res!);
  let token = context?.query?.token || null;
  let error = null;
  if (code && locale && userState) {
    try {
      axios.defaults.baseURL = Config.APP_URL;
      const result = await axios.get(Config.OAUTH_REDIRECT_URL, {
        params: {
          code,
          locale,
          userState,
          applicationId: Config.FA_CLIENT_ID,
        },
      });
      if (result?.data?.success && result?.data?.access_token) {
        token = result.data.access_token;
        cookies.set('access_token', token);
        cookies.set('refresh_token', result.data.refresh_token);
        cookies.set('pendingVerification');
      }
    } catch (e: any) {
      error = e.toString();
    }
  } else if (token) {
    cookies.set('access_token', token);
    cookies.set('refresh_token', context?.query?.refreshToken);
    cookies.set('pendingVerification');
  }
  return {
    props: { token, error },
  };
}
